import { memo } from 'react';
import { ChildrenProps, PageLoader } from '@dfl/mui-react-common';
import { useUser } from '@dfl/react-security';
import { ChangePasswordRequire } from 'modules/security/users/pages';
import AuthAppLayout from 'routes/layout/AuthAppLayout';

const UserControl = ({ children }: ChildrenProps) => {
  const { user } = useUser();

  if (!user) return <PageLoader size={'screen'} />;

  // changePasswordRequire
  if (user?.security?.requiredChangePassword) {
    return (
      <AuthAppLayout>
        <ChangePasswordRequire />
      </AuthAppLayout>
    );
  }
  return <>{children}</>;
};

export default memo(UserControl);
